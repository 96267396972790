import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import CategoryCascader from '@/core/components/common/forms/category/CategoryCascader.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'category-form',
    components: {
        CategoryCascader,
    },
    emits: ['get-data'],
    props: {
        passedCategories: {
            type: Object,
            required: true,
        },
        showCascader: {
            type: Boolean,
            required: false,
            default: true
        },
        showTitle: {
            type: Boolean,
            required: true,
            default: true
        },
        isDialog: {
            type: Boolean,
            required: false,
            default: false
        },
        isDynamicHeight: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        'dataToSubmit.categories': {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) {
                    this.$emit('get-data', value);
                }
            }
        },
    },
    data() {
        return {
            form: {},
            currentCategories: [],
            clonedCategories: [],
            clonedMapCategories: [],
            dataToSubmit: {}
        };
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, [
            'categories',
            'projectStatuses',
            'mainCategories',
            'subCategories',
            'toBePassedCategories'
        ]),
        availableCategories() {
            const { categories } = this;
            if (categories.length) {
                // return [];
                return this.transformCategoriesToOptions(categories);
            }
            return [];
        }
    },
    async created() {
        await this.getCategories();
        // localize
        this.dataToSubmit = {
            categories: JSON.parse(JSON.stringify(this.$props.passedCategories))
        };
        this.setProjectCategories();
    },
    methods: {
        ...mapActions(PROJECTS_STORE, [
            'getCategories',
            'setMainCategories',
            'setSubCategories',
            'setToBePassedCategories'
        ]),
        setProjectCategories() {
            const { dataToSubmit } = this;
            const projectCategories = dataToSubmit.categories;
            const mappedProjectCategories = [];
            if (projectCategories) {
                this.clonedCategories = projectCategories;
                projectCategories.forEach((category) => {
                    const mainCategory = category.id;
                    if (category.subCategories.length) {
                        category.subCategories.forEach((subcategory) => {
                            const arrCategory = [mainCategory, subcategory.id];
                            mappedProjectCategories.push(arrCategory);
                        });
                    }
                });
                this.currentCategories = mappedProjectCategories;
                this.clonedMapCategories = mappedProjectCategories;
            }
        },
        categoryChange(newCategories) {
            const { dataToSubmit, categories: originalCategories, clonedMapCategories: currentCategories } = this;
            const { categories: selectedCategories } = dataToSubmit;
            const latestSelectedCategories = newCategories.slice(currentCategories.length);
            for (let i = 0; i < latestSelectedCategories.length; i++) {
                const [mainCategoryId, subCategoryId] = latestSelectedCategories[i];
                const mainCategory = originalCategories.filter((item) => {
                    return item.id === mainCategoryId;
                });
                const selected = selectedCategories.filter((selectedCategory) => {
                    return selectedCategory.id === mainCategoryId;
                });
                if (mainCategory[0]) {
                    const subCategory = mainCategory[0].subCategories.filter((item) => {
                        return item.id === subCategoryId;
                    });
                    if (selected[0] && subCategory[0]) {
                        selected[0].subCategories.push(subCategory[0]);
                    }
                    else {
                        selectedCategories.push({
                            ...mainCategory[0],
                            subCategories: [subCategory[0]]
                        });
                    }
                }
            }
            dataToSubmit.categories = selectedCategories;
            this.setProjectCategories();
        },
        filterOptions(node, keyword) {
            return node.text.toLowerCase().includes(keyword.toLowerCase());
        },
        transformCategoriesToOptions(categories) {
            return categories.map((category) => {
                return {
                    value: category.id,
                    label: category.poLabel,
                    iconName: category.iconName,
                    children: category.subCategories.map((sub) => {
                        return { value: sub.id, label: sub.label };
                    }),
                };
            });
        },
        removeMainCategory(id) {
            const { dataToSubmit } = this;
            const { categories } = dataToSubmit;
            let newList = [];
            let newMainCategories = [];
            let newSubCategories = [];
            let newAddedCategories = [];
            newList = categories.filter((category) => {
                return category.id !== id;
            });
            newMainCategories = this.mainCategories.filter((value) => value !== id);
            this.setMainCategories(newMainCategories);
            // Find the object with the matching id in the availableCategories
            const category = this.availableCategories.find((cat) => cat.value === id);
            // Extract the values from the children array
            const values = category ? category.children.map((child) => child.value) : [];
            // remove all subcategories that corresponse in the removed id mainCategories
            newSubCategories = this.subCategories.filter((subCategory) => !values.includes(subCategory));
            this.setSubCategories(newSubCategories);
            // Filter addedCategories based on mainCategories
            newAddedCategories = this.toBePassedCategories.filter(([mainCategory]) => this.mainCategories.includes(mainCategory));
            this.setToBePassedCategories(newAddedCategories);
            dataToSubmit.categories = newList;
            this.setProjectCategories();
        },
        removeSubCategory(mainCategoryId, subCategoryId) {
            const { dataToSubmit } = this;
            const { categories } = dataToSubmit;
            let newList = [];
            let newSubCategories = [];
            let newAddedCategories = [];
            newList = categories.map((category) => {
                const { subCategories } = category;
                let tempList = [];
                let value = { ...category };
                if (category.id === mainCategoryId) {
                    tempList = subCategories.filter((item) => {
                        return item.id !== subCategoryId;
                    });
                    value.subCategories = tempList;
                }
                if (!value.subCategories.length) {
                    value = null;
                }
                return value;
            });
            // Remove subCategoryRemoveId from subCategory
            newSubCategories = this.subCategories.filter((subCategoryItem) => subCategoryItem !== subCategoryId);
            this.setSubCategories(newSubCategories);
            // Remove entries in addedCategory that match mainCategoryIdReference and subCategoryRemoveId
            newAddedCategories = this.toBePassedCategories.filter(([mainCategoryItem, subCategoryItem]) => {
                return !(mainCategoryItem === mainCategoryId && subCategoryItem === subCategoryId);
            });
            this.setToBePassedCategories(newAddedCategories);
            dataToSubmit.categories = newList.filter(Boolean);
            this.setProjectCategories();
        },
        getValidationText() {
            return 'Field is required';
        }
    }
});
